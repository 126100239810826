import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { withRouter } from 'next/router';

import Button from '/imports/core/ui/atoms/Button';
import CheckIcon from '/imports/core/ui/assets/new/CheckIcon';
import { Chevron } from '/imports/generator/ui/assets';
import { inputStyle, overrideInputStyleMixin } from '/imports/core/ui/mixins';
import { TEMPLATES_FONTS } from '/imports/pdf/core/api/constants';
import { withIntl } from '/imports/core/api/useIntl';
import { withTracking } from '/imports/core/hooks/useTracking';
import moment from 'moment';
import { prepareLanguageLabelBeforeTranslate } from 'imports/core/ui/components/LocaleSelect';

@withTracking
@withIntl
@withRouter
class Dropdown extends PureComponent {
  static propTypes = {
    selected: PropTypes.string,
    defaultSelected: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
        value: PropTypes.string,
      }),
    ),
    onChange: PropTypes.func,
    dark: PropTypes.bool,
    stripe: PropTypes.bool,
    scrollOption: PropTypes.bool,
    expandTitle: PropTypes.string,
    isControlled: PropTypes.bool,
    borderActive: PropTypes.bool,
    borderError: PropTypes.bool,
    isFontSelector: PropTypes.bool,
    scrollOnKeyPress: PropTypes.bool,
    name: PropTypes.string,
    locale: PropTypes.string,
    trackEvent: PropTypes.func,
    t: PropTypes.func,
    placeholderSlug: PropTypes.string,
    preview: PropTypes.any,
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);
    this.formattedDefaultSelected = this.formattedDefaultSelected.bind(this);
    this.state = {
      expanded: false,
      hovered: this.props.selected || '',
      selected:
        this.props.options.find(
          (o) =>
            o.value.toString() === (this.props.isControlled ? this.props.selected : this.formattedDefaultSelected()),
        ) || {},
    };
  }

  /** format default selected, after upload the default selected if in format YYYY-MM-dd HH:mm:SS */
  formattedDefaultSelected = () => {
    const { defaultSelected } = this.props;
    if (moment(defaultSelected, moment.ISO_8601, true).isValid()) {
      return moment(defaultSelected).format('YYYY');
    }

    return defaultSelected;
  };

  scrollOption = () => {
    const { scrollOption } = this.props;

    if (scrollOption) {
      const currentYear = new Date().getFullYear();
      const elem = document.getElementById(`value-${this.formattedDefaultSelected() || currentYear}`);
      if (elem) {
        const offSet = elem.offsetTop;
        this.dropdown.scrollTop = offSet;
      }
    }
  };

  outsideClick = (event) => {
    if (!this.dropdown.contains(event.target) && this.state.expanded) {
      this.setState({ expanded: !this.state.expanded });
    }
  };

  handleDropdownScroll = (event) => {
    const { name } = this.props;
    const letter = event.key;
    const eventKeyIsAlpha = /[A-Za-z]/.test(letter);
    if (this.state.expanded && eventKeyIsAlpha) {
      const buttons = [...document.querySelectorAll(`#dropdown-menu-${name} > button[id^="value-"]`)];
      let targetButton = null;
      for (const button of buttons) {
        const buttonText = button.textContent.trim();
        const shouldUpdate =
          !targetButton && (buttonText.startsWith(letter) || buttonText.startsWith(letter.toUpperCase()));
        if (shouldUpdate) {
          if (!this.lastTargetButton || this.lastTargetButton !== button) {
            targetButton = button;
            this.lastTargetButton = button;
          } else {
            targetButton = null;
          }
        }
      }

      if (targetButton) {
        const yPosition =
          targetButton.getBoundingClientRect().top -
          this.dropdown.getBoundingClientRect().top +
          this.dropdown.scrollTop;
        this.dropdown.scrollTo({
          top: yPosition,
        });
      }
    }
  };

  componentDidMount() {
    this.scrollOption();
    const parentElement = this.dropdownComponent.parentNode?.parentNode?.parentNode;
    if (parentElement && parentElement.childNodes) {
      Array.from(parentElement.childNodes).forEach((childNode) => {
        if (childNode.nodeType === Node.ELEMENT_NODE && !childNode.contains(this.dropdownComponent)) {
          childNode.addEventListener('click', this.outsideClick);
        }
      });
    }
    if (this.props.scrollOnKeyPress) {
      document.addEventListener('keydown', this.handleDropdownScroll);
    }
  }

  componentWillUnmount() {
    const parentElement = this.dropdownComponent.parentNode?.parentNode?.parentNode;
    if (parentElement && parentElement.childNodes) {
      Array.from(parentElement.childNodes).forEach((childNode) => {
        if (childNode.nodeType === Node.ELEMENT_NODE && !childNode.contains(this.dropdownComponent)) {
          childNode.removeEventListener('click', this.outsideClick);
        }
      });
    }
    if (this.props.scrollOnKeyPress) {
      document.removeEventListener('keydown', this.handleDropdownScroll);
    }
  }

  componentDidUpdate(prevProps) {
    const prevTitle = prevProps.options.find((o) => o.value.toString() === prevProps.selected)?.title;
    const currentTitle = this.props.options.find((o) => o.value.toString() === this.props.selected)?.title;
    if (
      (prevProps.selected !== this.props.selected && this.props.isControlled) ||
      this.props.locale !== prevProps.locale ||
      (this.props.isFontSelector && prevTitle !== currentTitle)
    ) {
      this.setState({ selected: this.props.options.find((o) => o.value.toString() === this.props.selected) || {} });
    }
  }

  windowClick = () => {
    this.setState({ expanded: false });
    document.removeEventListener('click', this.windowClick);
  };

  toggleExpanded = (e) => {
    e.stopPropagation();
    if (this.state.expanded === false) {
      document.addEventListener('click', this.windowClick);
    } else {
      document.removeEventListener('click', this.windowClick);
      this.lastTargetButton = null;
    }
    this.setState((st) => ({ expanded: !st.expanded }));
  };

  handleSelect = (option) => {
    const { onChange, name, trackEvent } = this.props;
    if (window) {
      switch (name) {
        case 'language':
          trackEvent('resume_language_selected', { resume_language: option.value });
          break;
        case 'headingFont':
          trackEvent('resume_headingFont_selected', { resume_headingFont: option.value });
          break;
        case 'contentFont':
          trackEvent('resume_contentFont_selected', { resume_contentFont: option.value });
          break;
        default:
          break;
      }
    }
    if (name == 'template' && option.value == 'budapest-v2') {
      onChange({ target: { name: name, value: 'budapest', version: 2 } });
    } else {
      onChange({ target: { name: name, value: option.value } });
    }

    this.setState({ selected: option, expanded: false });
  };

  handleHover = (hovered) => {
    this.setState({ hovered });
  };

  title() {
    const { expanded, selected } = this.state;
    const { dark, placeholder, t, placeholderSlug, expandTitle } = this.props;
    if (dark && expanded) {
      return expandTitle;
    }
    const languageTitle = prepareLanguageLabelBeforeTranslate(selected.title);
    let title = selected.titleSlug && t(selected.titleSlug) ? t(selected.titleSlug) : selected.title;
    if (title && selected.appendText) title = `${title} ${selected.appendText}`;
    return (
      t(`language.${languageTitle}`) ||
      title || <span>{placeholderSlug && t(placeholderSlug) ? t(placeholderSlug) : placeholder}</span>
    );
  }

  sortOptions = () => {
    const { options, t, name } = this.props;
    if (name === 'language') {
      const translatedOptions = options.map((option) => {
        const translatedTitle = t(`language.${prepareLanguageLabelBeforeTranslate(option.title)}`);
        return {
          ...option,
          translatedTitle,
        };
      });

      translatedOptions.sort((a, b) => {
        return a.translatedTitle.localeCompare(b.translatedTitle);
      });
      return translatedOptions;
    } else {
      return options;
    }
  };

  render() {
    const { expanded, selected, hovered } = this.state;
    const { name } = this.props;
    const {
      options,
      dark,
      preview,
      t,
      isControlled,
      selected: customSelected,
      borderActive,
      borderError,
      isFontSelector,
    } = this.props;
    const chevron = expanded ? <Chevron.Up /> : <Chevron.Down />;
    const selectedWrapperName = `interactive-element-clickable-${name}`;
    const sortedOptions = this.sortOptions();

    return (
      <DropdownContent dark={dark} huge={preview} ref={(ref) => (this.dropdownComponent = ref)}>
        <Selected
          onClick={this.toggleExpanded}
          dark={dark}
          preview={preview}
          expanded={expanded}
          borderActive={borderActive}
          borderError={borderError}
          font={isControlled ? customSelected : selected.value}
          isFontSelector={isFontSelector}
          name={selectedWrapperName}
        >
          {this.title()}
          <ChevronContainer preview={preview}>{chevron}</ChevronContainer>
        </Selected>
        <DropdownMenu
          dark={dark}
          expanded={expanded}
          onClick={(e) => e.stopPropagation()}
          ref={(ref) => (this.dropdown = ref)}
          huge={preview}
          preview={preview}
          className="dropdown-menu"
          id={`dropdown-menu-${name}`}
        >
          {(sortedOptions.length &&
            sortedOptions.map((option) => {
              return (
                <DropdownButton
                  type="button"
                  key={option.value}
                  dark={dark}
                  onMouseEnter={() => this.handleHover(option.value)}
                  onMouseLeave={this.handleHover}
                  onClick={() => this.handleSelect(option)}
                  selected={isControlled ? option.value === customSelected : option.value === selected.value}
                  hovered={hovered === option.value}
                  preview={preview}
                  id={`value-${option.value}`}
                  font={option.value}
                  isFontSelector={isFontSelector}
                >
                  <CheckIcon />
                  {name === 'language' ? (
                    <> {t(`language.${prepareLanguageLabelBeforeTranslate(option.title)}`)}</>
                  ) : option.titleSlug && t(option.titleSlug) ? (
                    t(option.titleSlug)
                  ) : (
                    option.title
                  )}
                </DropdownButton>
              );
            })) ||
            this.props.children()}
        </DropdownMenu>
      </DropdownContent>
    );
  }
}

export const DropdownContent = styled.div`
  position: relative;
  flex-flow: column nowrap;
  align-items: flex-start;
  width: 100%;

  > button {
    font-size: 32px;
  }

  ${(p) =>
    p.dark &&
    css`
      width: auto;
    `}

  ${({ preview }) =>
    preview &&
    css`
      margin-left: 2.8%;
    `}

  ${({ theme: { isRTL }, preview }) =>
    isRTL &&
    css`
      direction: rtl;
      margin-right: ${preview ? '2.8%' : 'auto'};
    `}
`;

export const DropdownMenu = styled.div`
  width: auto;
  height: auto;
  max-height: 154px;
  padding: 6px 0;
  background-color: var(--light-values-white);
  border: solid 2px #e6e6ff;
  border-top: none;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 15px 25px -10px rgba(0, 0, 0, 0.1);
  overflow: auto;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 15px;
  line-height: 18px;
  letter-spacing: normal;
  color: #3d4047;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 226;
  transform-origin: top;
  transform: scaleY(0);
  opacity: 0;
  &::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.colors.gray.regular};
    border: 4px solid ${(p) => p.theme.colors.gray.lighter};
    border-radius: 6px;
    ${(p) =>
      p.dark &&
      css`
        border-color: ${(p) => p.theme.colors.black};
      `}
    &:hover {
      background-color: ${(p) => p.theme.colors.primary};
    }
  }
  ${({ expanded }) =>
    expanded &&
    css`
      transform: scaleY(1);
      opacity: 1;
      box-shadow: 0 15px 25px -10px rgba(0, 0, 0, 0.1);
    `}

  ${(p) =>
    p.dark &&
    css`
      top: calc(100% + 17px);
      width: 170px;
      height: 176px;
      background-color: ${(p) => p.theme.colors.black};
      box-shadow: none;
      padding: 0 20px 7px 20px;
    `}

    ${({ preview, theme }) =>
    preview &&
    css`
      width: 180px;
      border-top: 0;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
      top: 100%;
      border: none;
      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${theme.colors.gray.regular};
        border: 4px solid transparent;
      }

      button {
        font-size: 14px;
        color: ${theme.colors.white};
      }
    `}
     ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      border: 1px solid #1688fe !important;
      border-radius: 4px;
      max-height: 184px !important;
      padding: 0;
      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(20, 20, 31, 0.48) !important;
        border-radius: 2px;
      }
    `}
`;

const dropdownButtonCSS = css`
  text-align: left;
  width: 100%;
  display: block;
  color: #484870;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 15px;
  font-weight: 400;
  padding: 6px 16px;
  line-height: 1.2;
  letter-spacing: normal;
  background: transparent;
  border: 0;
  svg {
    display: none;
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: ltr;
      text-align: right;
    `}

  &:hover {
    box-shadow: none;
    color: #1688fe;
    background: #f7f7fc;
  }

  ${(p) =>
    p.selected &&
    css`
      color: ${p.theme.colors.gray.light};
      ${p.theme.max('xs')`
      color: #1688fe;
      background-color: #f7f7fc;
    `}
    `}
  ${(p) =>
    p.hovered &&
    css`
      color: ${p.theme.colors.primary};
      background: ${rgba(p.theme.colors.primary, 0.1)};
      border-radius: 0;
    `}
${(p) =>
    p.dark &&
    css`
      padding: 10px 0px;
      color: var(--light-values-white);
      ${p.selected &&
      css`
        color: ${p.theme.colors.gray.regular};
      `}
      &:hover {
        background: none;
      }
      ${p.hovered &&
      css`
        background: none;
      `}
    `}
  ${(p) =>
    p.isFontSelector &&
    css`
      font-family: ${(p) => TEMPLATES_FONTS[p.font]?.regular};
      &:hover {
        font-family: ${(p) => TEMPLATES_FONTS[p.font]?.regular};
      }
    `}
    ${({ theme }) =>
    theme.isJobTrack &&
    css`
      font-family: ${theme.font.family.websiteSemiBold} !important;
      font-size: 13px !important;
      line-height: 20px !important;
      &:hover {
        background-color: var(--light-values-light-extra) !important;
        color: var(--dark-values-black) !important;
      }
    `}
`;

export const DropdownButton = styled((p) => <Button {...p} />)`
  ${dropdownButtonCSS}
`;

export const ChevronContainer = styled.span`
  line-height: 0;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-30%);

  svg > path {
    stroke: ${({ theme }) => theme.colors.primary};
  }

  ${({ preview }) =>
    preview &&
    css`
      svg > path {
        stroke: ${({ theme }) => theme.colors.primary}!important;
      }
    `}

  ${({ theme }) =>
    theme.max('xs')`
      svg > path {
        stroke: #1688fe !important;
      }
    `}
    ${({ theme }) =>
    theme.isJobTrack &&
    theme.max('xs')`
       svg > path {
        stroke: #4C4C55 !important;
      }
    `}

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      right: auto;
      left: 10px !important;
    `}
`;

const selectedStyle = css`
  ${inputStyle}
  ${overrideInputStyleMixin}
  padding: 13px 35px 9px 16px;
  color: #484870;
  border: solid 2px #e6e6ff;
  box-shadow: none;
  border: 2px solid #e6e6ff;
  ${({ theme }) =>
    theme.designV2 &&
    css`
      height: 48px;
      align-self: stretch;
      flex-grow: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      padding: 13px 18px 13px;
      border: solid 1px #e3e3e4;
      background-color: #ffffff;
      flex-direction: row;
    `}
  ${(p) =>
    p.dark &&
    css`
      background: transparent;
      color: white;
      border: 0;
      padding: 6px 56px 2px 12px;
      ${({ theme: { isRTL } }) =>
        isRTL &&
        css`
          padding: 6px 12px 2px 56px;
        `}

      > i {
        color: white;
        right: 24px;
      }
    `}

border-radius: ${({ expanded, theme }) =>
    theme.designV2 ? (expanded ? '8px 8px 0 0' : '8px') : expanded ? '3px 3px 0 0' : '3px'};
  ${({ preview, theme, expanded }) =>
    preview &&
    css`
      min-width: 180px;
      width: 100%;
      font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
      padding-left: 12px;
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 16px;
      box-shadow: 0 3px 4px -3px rgba(15, 15, 15, 0.12);
      color: ${theme.colors.white};

      ${expanded &&
      css`
        border-radius: 3px 3px 0 0;
      `}
    `}

  ${({ borderActive, expanded }) =>
    borderActive &&
    expanded &&
    css`
      border: solid 2px #1688fe !important;
    `}
  ${({ borderError, theme }) =>
    borderError &&
    css`
      border: solid 2px ${theme.colors.danger} !important;
    `}
span {
    color: ${({ theme }) => theme.colors.gray.regular} !important;
  }
  ${(p) =>
    p.isFontSelector &&
    css`
      font-family: ${(p) => TEMPLATES_FONTS[p.font]?.regular};
    `}
`;

const Selected = styled.p`
  ${selectedStyle}
  margin: 0;
  user-select: none;
  cursor: pointer;
  outline: none;
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      border: 1px solid #e3e3e4 !important;
      border-radius: 8px !important;
      padding: 13px 12px !important;
      span {
        font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
        color: ${(p) => p.theme.colors.gray.regular};
        font-size: 15px;
        line-height: 22px;
      }
    `}
`;

export default Dropdown;
