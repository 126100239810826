import { useContext, useEffect } from 'react';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import dynamic from 'next/dynamic';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Experiment as MarvelExperiment, Variant as MarvelVariant, emitter } from '@marvelapp/react-ab-test';
import { ThemeProvider } from 'styled-components';
import AccountContext from '/imports/core/api/accounts/accountContext';
import {
  AI_SUGGESTION_ACTIVE_DOMAIN,
  AI_SUGGESTION_ALL_VARIANTS_DOMAIN,
  AI_SUGGESTION_DOMAIN,
  getActiveVariantStorage,
  removeExperiment,
  checkAIPopupVariantByHost,
  CheckAIPopupWinningVariants,
  getActiveRepositingVar,
  getAISuggestionMobileVariantsByLocale,
  getAISuggestionVariant,
} from '/lib/helpers';
import env from '/env';
import ResumeGeneratorSkeleton from '/imports/generator/ui/skeleton/ResumeGeneratorSkeleton';
import useIntl from '/imports/core/api/useIntl';
import { useResponsive } from '/imports/core/api/responsiveContext';
import useTracking from '/imports/core/hooks/useTracking';
import { useRouter } from 'next/router';
import { getCountry } from 'imports/checkout/api/utils';
import SkillsTagsWithMoreSpaceExp from 'imports/experiment/SkillsTagsWithMoreSpaceExp';

const isProd = env.NODE_ENV === 'production';

const GeneratorDynamic = dynamic(() => import('/imports/generator/ui/pages/GeneratorPage'), {
  loading: () => <ResumeGeneratorSkeleton />,
});

function GeneratorWrapper(props) {
  const { currentUser, refetch } = useContext(AccountContext);
  const { host, isMobile, isGeneratorMobileView } = useResponsive();
  const { trackUserIdentity } = useTracking();
  const { locale } = useIntl();
  const country = getCountry();
  const {
    pageProps: { BlockCountry, BlockGoogleMapCountry },
  } = props;
  const router = useRouter();
  useEffect(() => {
    // Manage AI feature experiment traffic
    CheckAIPopupWinningVariants(host, isGeneratorMobileView);

    // Clean up localStorage for removed experiments
    const experimentsToRemove = [
      'address_autocomplete_experiment',
      'welcome_gift_popup_experiment',
      'column_switch_tooltip_experiment',
      'heading_wizard_automatic_experiment',
      'side_inverse_experiment',
      'scale_preview_experiment',
      'video_preview_experiment',
      'hide_header_finalise_experiment',
      'drag_and_drop_experiment',
      'resume_preview_button_experiment',
      'resume_preview_button_new_experiment',
      'drag_drop_v2_experiment',
      'pre_written_text_v2_experiment',
      'exp_ai_cta',
      'exp_ai_modal',
      'exp_ai_text',
      'exp_ai_design',
      'exp_ai_title',
      'exp_ai_modal_v2',
      'ai_suggestion_cta_badge',
      'exp_block_expandable',
      'exp_template_cv',
      'openai_models',
      'exp_expand_preview_button',
      'exp_heading_start_title',
      'exp_column_switcher',
      'new_flow_last_step_exp',
      'exp_career_status',
      'exp_career_video',
      'exp_drag_and_drop',
      'exp_auto_fetch_related_skills',
      'ai_popup_experience_prompt_exp',
      'skill_autocomplete_exp_en',
      'job_title_autocomplete_exp_en',
      'exp_places_autocomplete',
      'exp_places_autocomplete_v2',
      'exp_summary_suggestion',
      'exp_auto_expand_description_mobile',
      'exp_exit_popup',
      'exp_scoring_plugin',
      'block_reposition_toggle_exp',
      'exp_drag_and_drop_mobile',
      'exp_new_drag_and_drop',
      'exp_other_section_DD_V2',
      'exp_address_field',
      'exp_new_other_section_DD_V2',
      'exp_skills_DD_V2',
      'exp_template_budapest_v2',
      'exp_educ_exper_desk_DD',
      'exp_placekit_autocomplete',
      'exp_bullet_point_setup_in_text_field',
    ];

    if (locale !== 'ar') experimentsToRemove.push('exp_add_block_step');
    if (locale !== 'fr' || country !== 'FR') experimentsToRemove.push('exp_career_status_v2');
    experimentsToRemove.forEach((exp) => removeExperiment(exp));

    if (currentUser) {
      trackUserIdentity(currentUser, true, {}, false);
    }
  }, [currentUser, host, locale, trackUserIdentity]);

  const invertTheme = (theme) => ({ ...theme, designV2: true });

  const renderGeneratorSide = () => (
    <ThemeProvider theme={invertTheme}>
      <GeneratorDynamic
        currentUser={currentUser}
        userRefetch={refetch}
        BlockCountry={BlockCountry}
        BlockGoogleMapCountry={BlockGoogleMapCountry}
      />
    </ThemeProvider>
  );

  const renderCareerAbTest = () => {
    const { locale } = useIntl();
    const country = getCountry();
    if (locale !== 'fr' && country !== 'FR') return renderGeneratorSide();
    return (
      <MarvelExperiment name="exp_career_status_v2">
        <MarvelVariant name="without_first_step_flow">{renderGeneratorSide()}</MarvelVariant>
        <MarvelVariant name="control">{renderGeneratorSide()}</MarvelVariant>
      </MarvelExperiment>
    );
  };
  const RenderToggleABtest = () => {
    const { isMobile } = useResponsive();
    const activeVar = getActiveRepositingVar();
    const withComplete = activeVar === 'with_complete_repositioning';
    if (isMobile || !withComplete) return renderCareerAbTest();
    return (
      <MarvelExperiment name="block_reposition_toggle_exp_v2">
        <MarvelVariant name="control">{renderCareerAbTest()}</MarvelVariant>
        <MarvelVariant name="with_modal">{renderCareerAbTest()}</MarvelVariant>
        <MarvelVariant name="with_tooltip">{renderCareerAbTest()}</MarvelVariant>
      </MarvelExperiment>
    );
  };

  const RenderAIModel = () => {
    const currentVariant = ['control', 'gpt-4o', 'gpt-4o-mini'];
    const localVariant = getActiveVariantStorage('ai_suggestion_openai_model');
    const reinitializeExp = localVariant && !currentVariant.includes(localVariant);
    if (typeof localStorage !== 'undefined' && reinitializeExp) removeExperiment('ai_suggestion_openai_model');
    return (
      <MarvelExperiment name="ai_suggestion_openai_model">
        <MarvelVariant name="control">
          <RenderCTATextExp />
        </MarvelVariant>
        <MarvelVariant name="gpt-4o">
          <RenderCTATextExp />
        </MarvelVariant>
        <MarvelVariant name="gpt-4o-mini">
          <RenderCTATextExp />
        </MarvelVariant>
      </MarvelExperiment>
    );
  };

  const RenderCTATextExp = () => {
    const stopAiSuggestion = ['en', 'fr', 'es'].includes(locale);
    if (stopAiSuggestion) return <RenderToggleABtest />;
    return (
      <MarvelExperiment name="ai_suggestion_cta">
        <MarvelVariant name="ai_suggestion_cta_v1">
          <RenderToggleABtest />
        </MarvelVariant>
        <MarvelVariant name="ai_suggestion_cta_v2">
          <RenderToggleABtest />
        </MarvelVariant>
        <MarvelVariant name="ai_suggestion_cta_v3">
          <RenderToggleABtest />
        </MarvelVariant>
        <MarvelVariant name="ai_suggestion_cta_v4">
          <RenderToggleABtest />
        </MarvelVariant>
      </MarvelExperiment>
    );
  };

  const RenderAISuggestionExp = () => {
    const variantComponents = {
      with_ai_suggestion: <RenderCTATextExp />,
      without_ai_suggestion: <RenderToggleABtest />,
      with_ai_suggestion_v2: <RenderAIModel />,
    };
    const currentExpName = isGeneratorMobileView ? 'ai_suggestion_mobile' : 'ai_suggestion';
    const localVariant = getActiveVariantStorage(currentExpName);
    const shouldActiveAI = AI_SUGGESTION_ACTIVE_DOMAIN.includes(host);
    const isWithFeatureOrExp = [...AI_SUGGESTION_DOMAIN, ...AI_SUGGESTION_ACTIVE_DOMAIN].includes(host);
    const shouldHaveAllVariants = AI_SUGGESTION_ALL_VARIANTS_DOMAIN.includes(host);
    const reinitializeExp = localVariant && !checkAIPopupVariantByHost(host, localVariant, isGeneratorMobileView);
    const mobileVariantList = getAISuggestionMobileVariantsByLocale(locale);
    const currentMobileAppliedVariant = getAISuggestionVariant(true, locale);

    useEffect(() => {
      if (mobileVariantList.length < 2) removeExperiment('ai_suggestion_mobile');
    }, []);

    if (reinitializeExp) removeExperiment(currentExpName);
    //For mobile version
    if (isGeneratorMobileView) {
      if (mobileVariantList.length > 1 && isWithFeatureOrExp) {
        marvelEmitter.defineVariants('ai_suggestion_mobile', mobileVariantList);
        if (typeof localStorage !== 'undefined') {
          const currentMobileVariant = getActiveVariantStorage('ai_suggestion_mobile');
          const reset = currentMobileVariant && !mobileVariantList.includes(currentMobileVariant);
          reset && marvelEmitter.setActiveVariant('ai_suggestion_mobile', _.sample(mobileVariantList));
        }
        return (
          <MarvelExperiment name="ai_suggestion_mobile">
            {mobileVariantList.map((variant) => (
              <MarvelVariant name={variant} key={variant}>
                {variantComponents[variant]}
              </MarvelVariant>
            ))}
          </MarvelExperiment>
        );
      }
      return currentMobileAppliedVariant ? variantComponents[currentMobileAppliedVariant] : <RenderToggleABtest />;
    }

    //For desktop version
    if (shouldActiveAI) {
      return (
        <MarvelExperiment name="ai_suggestion">
          <MarvelVariant name="with_ai_suggestion">
            <RenderCTATextExp />
          </MarvelVariant>
          <MarvelVariant name="with_ai_suggestion_v2">
            <RenderAIModel />
          </MarvelVariant>
        </MarvelExperiment>
      );
    } else if (shouldHaveAllVariants || !isProd) {
      return (
        <MarvelExperiment name="ai_suggestion">
          <MarvelVariant name="with_ai_suggestion">
            <RenderCTATextExp />
          </MarvelVariant>
          <MarvelVariant name="without_ai_suggestion">
            <RenderToggleABtest />
          </MarvelVariant>
          <MarvelVariant name="with_ai_suggestion_v2">
            <RenderAIModel />
          </MarvelVariant>
        </MarvelExperiment>
      );
    } else if (AI_SUGGESTION_DOMAIN.includes(host)) {
      return (
        <MarvelExperiment name="ai_suggestion">
          <MarvelVariant name="with_ai_suggestion">
            <RenderCTATextExp />
          </MarvelVariant>
          <MarvelVariant name="without_ai_suggestion">
            <RenderToggleABtest />
          </MarvelVariant>
        </MarvelExperiment>
      );
    }
    return <RenderToggleABtest />;
  };

  /**AB test exp add block */

  const RenderExpAddBlockStep = () => {
    if (locale !== 'ar') return <RenderAISuggestionExp />;
    return (
      <MarvelExperiment name="exp_add_block_step">
        <MarvelVariant name="control">
          <RenderAISuggestionExp />
        </MarvelVariant>
        <MarvelVariant name="with_additional_add_block_step">
          <RenderAISuggestionExp />
        </MarvelVariant>
      </MarvelExperiment>
    );
  };

  const RenderExpJobTitleAutocomplete = () => {
    if (locale !== 'en') return <RenderExpAddBlockStep />;
    return (
      <MarvelExperiment name="job_title_autocomplete_exp_en_new">
        <MarvelVariant name="control">
          <RenderExpAddBlockStep />
        </MarvelVariant>
        <MarvelVariant name="with_autocomplete">
          <RenderExpAddBlockStep />
        </MarvelVariant>
        <MarvelVariant name="with_autocomplete_no_arrow">
          <RenderExpAddBlockStep />
        </MarvelVariant>
      </MarvelExperiment>
    );
  };

  const RenderExpSkillAutocomplete = () => {
    if (locale !== 'en') return <RenderExpAddBlockStep />;
    return (
      <MarvelExperiment name="skill_autocomplete_exp_en_new">
        <MarvelVariant name="control">
          <RenderExpJobTitleAutocomplete />
        </MarvelVariant>
        <MarvelVariant name="with_autocomplete">
          <RenderExpJobTitleAutocomplete />
        </MarvelVariant>
        <MarvelVariant name="with_autocomplete_no_arrow">
          <RenderExpJobTitleAutocomplete />
        </MarvelVariant>
      </MarvelExperiment>
    );
  };

  const RenderExpAutoFetchRelatedSkills = () => {
    if (locale !== 'en') return <RenderExpAddBlockStep />;
    return (
      <MarvelExperiment name="exp_auto_fetch_related_skills_new">
        <MarvelVariant name="control">
          <RenderExpSkillAutocomplete />
        </MarvelVariant>
        <MarvelVariant name="with_big_screen_popup">
          <RenderExpSkillAutocomplete />
        </MarvelVariant>
        <MarvelVariant name="with_cloud_design">
          <RenderExpSkillAutocomplete />
        </MarvelVariant>
      </MarvelExperiment>
    );
  };

  const RenderExpExtraDegreeForArabic = () => {
    if (locale !== 'ar') return <RenderExpAutoFetchRelatedSkills />;
    return (
      <MarvelExperiment name="exp_extra_degree_for_arabic">
        <MarvelVariant name="control_degree_list">
          <RenderExpAutoFetchRelatedSkills />
        </MarvelVariant>
        <MarvelVariant name="with_extra">
          <RenderExpAutoFetchRelatedSkills />
        </MarvelVariant>
      </MarvelExperiment>
    );
  };

  const RenderExpSkillsTags = () => {
    if (!isMobile) return <RenderExpExtraDegreeForArabic />;
    return (
      <MarvelExperiment name="exp_skills_tags_v1">
        <MarvelVariant name="with_tags_default_bar">
          <RenderExpExtraDegreeForArabic />
        </MarvelVariant>
        <MarvelVariant name="with_tags_default_tags">
          <RenderExpExtraDegreeForArabic />
        </MarvelVariant>
        <MarvelVariant name="skills_tag_control">
          <RenderExpExtraDegreeForArabic />
        </MarvelVariant>
      </MarvelExperiment>
    );
  };

  const RenderExpPlacekitAutocomplete = () => {
    const { query } = router;
    const isActiveExp = query.step === 'finish';

    if (!isActiveExp) return <RenderExpSkillsTags />;
    emitter.defineVariants('exp_placekit_autocomplete_v2', [
      'control',
      'placekit_with_dropdown',
      'placekit_without_dropdown',
      'without_api',
    ]);
    return (
      <MarvelExperiment name="exp_placekit_autocomplete_v2">
        <MarvelVariant name="control">
          <RenderExpSkillsTags />
        </MarvelVariant>
        <MarvelVariant name="placekit_with_dropdown">
          <RenderExpSkillsTags />
        </MarvelVariant>
        <MarvelVariant name="placekit_without_dropdown">
          <RenderExpSkillsTags />
        </MarvelVariant>
        <MarvelVariant name="without_api">
          <RenderExpSkillsTags />
        </MarvelVariant>
      </MarvelExperiment>
    );
  };

  const RenderNotificationSkills = () => {
    const { query } = router;
    const isActiveExp = query.step !== 'skills';
    if (isActiveExp || !isMobile) return <RenderExpPlacekitAutocomplete />;

    return (
      <MarvelExperiment name="exp_notification_limit">
        <MarvelVariant name="control">
          <RenderExpPlacekitAutocomplete />
        </MarvelVariant>
        <MarvelVariant name="with_modal">
          <RenderExpPlacekitAutocomplete />
        </MarvelVariant>
      </MarvelExperiment>
    );
  };

  const RenderExpScoringPlugin = () => {
    return (
      <MarvelExperiment name="exp_scoring_plugin_v2">
        <MarvelVariant name="control_plugin">
          <RenderNotificationSkills />
        </MarvelVariant>
        <MarvelVariant name="with_popup_with_plugin">
          <RenderNotificationSkills />
        </MarvelVariant>
        <MarvelVariant name="without_popup_with_plugin">
          <RenderNotificationSkills />
        </MarvelVariant>
      </MarvelExperiment>
    );
  };

  const RenderExpWithoutAddressField = () => {
    return (
      <MarvelExperiment name="exp_address_field">
        <MarvelVariant name="control">
          <RenderExpScoringPlugin />
        </MarvelVariant>
        <MarvelVariant name="without_address_field">
          <RenderExpScoringPlugin />
        </MarvelVariant>
      </MarvelExperiment>
    );
  };

  return (
    <SkillsTagsWithMoreSpaceExp>
      <RenderExpWithoutAddressField />
    </SkillsTagsWithMoreSpaceExp>
  );
}

GeneratorWrapper.displayName = 'GeneratorWrapper';

GeneratorWrapper.propTypes = {
  pageProps: PropTypes.object,
};

export default GeneratorWrapper;
