import { Experiment as MarvelExperiment, Variant as MarvelVariant } from '@marvelapp/react-ab-test';

export default function SkillsTagsWithMoreSpaceExp(props) {
  return (
    <MarvelExperiment name="exp_skills_tags_with_more_space_exp">
      <MarvelVariant name="control">{props.children}</MarvelVariant>
      <MarvelVariant name="with_space_with_litle_margin">{props.children}</MarvelVariant>
      <MarvelVariant name="with_space_properly_aligned">{props.children}</MarvelVariant>
    </MarvelExperiment>
  );
}
