import PreviewArrowIcon from '../assets/new/PreviewArrowIcon';
import React, { PureComponent, Fragment } from 'react';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import PropTypes from 'prop-types';
import qs from 'qs';
import throttle from 'lodash/throttle';
import { graphql } from 'react-apollo';
import styled, { css } from 'styled-components';
import { withRouter } from 'next/router';
import _ from 'lodash';
import Button from '/imports/core/ui/atoms/Button';
import { apolloClient as client } from '/lib/initApollo';
import { extractBlockInfo, getIsAgent, handleBlockRepositioning, replacedBlocks } from '/imports/generator/api/helpers';
import Flex from '/imports/core/ui/atoms/Flex';
import { GET_SAVING_STATUS } from '/imports/generator/api/apollo/client/queries';
import { getJobTrackRoute, getPreviewBlocksHeight, packItems } from '/lib/helpers';
import Link, { Push } from '/components/Link';
import { LIST_RESUMES } from '/imports/dashboard/api/apollo/client/queries';
import PDFViewer from '/imports/pdf/core/ui/components/PDFViewer';
import SvgIcon from '/imports/core/ui/atoms/SvgIcon';
import ToggleDeactiveModal from '/imports/generator/ui/atoms/ToggleDeactiveModal.js';
import {
  UPDATE_BLOCK_FIELD,
  UPDATE_BLOCK_ITEM_FIELD,
  UPDATE_BLOCK_POSITION,
  UPDATE_RESUME_DETAIL,
} from '/imports/generator/api/apollo/client/mutations';
import WebsiteButtonWrapper from '/imports/generator/ui/atoms/WebsiteButtonWrapper';
import { withBlockResposition } from '/imports/generator/context/blockresposition.context';
import { withIntl } from '/imports/core/api/useIntl';
import { withResponsiveContext } from '/imports/core/api/responsiveContext';
import { withTracking } from '/imports/core/hooks/useTracking';
import { blockImmutableUpdate } from 'imports/generator/api/apollo/client/helpers';

const getWidthUltimate = (width, height) => {
  const X = width;
  let Y = width / height;
  if (Y < 1.6) Y = 1.6;
  if (Y > 2.1) Y = 2.1;
  const P = 72 - 15.3 * Y - 0.004 * X;
  const R = P - 0.2 * (P - 38) * (P - 33);
  return R > 39.5 ? 39.5 : R;
};

const getWidth = (width, isCoverLetter, height) => {
  if (width <= 1366 || isCoverLetter) return 36;
  const ratio = getWidthUltimate(width, height);
  return ratio > 39.5 ? 39.5 : ratio < 32 ? 32 : ratio;
};
@withTracking
@withResponsiveContext
@withIntl
@withRouter
@graphql(UPDATE_RESUME_DETAIL, { name: 'updateResumeDetail' })
@graphql(LIST_RESUMES, { name: 'listResumes' })
@graphql(UPDATE_BLOCK_POSITION, { name: 'updateBlockPosition' })
@graphql(UPDATE_BLOCK_FIELD, { name: 'updateBlockField' })
class Preview extends PureComponent {
  static propTypes = {
    router: PropTypes.object,
    coverLetter: PropTypes.object,
    loading: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
    locale: PropTypes.string,
    updateBlockField: PropTypes.func,
    resume: PropTypes.object,
    userRefetch: PropTypes.func,
    listResumes: PropTypes.array,
    host: PropTypes.string,
    trackEvent: PropTypes.func,
    context: PropTypes.object,
    isCoverLetter: PropTypes.bool,
    source: PropTypes.object,
    showVideoPreview: PropTypes.bool,
    t: PropTypes.func,
    updateImmue: PropTypes.func,
    updateBlockPosition: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.toggleRef = React.createRef();
  }

  state = {
    isCoverLetter: !!this.props.coverLetter,
    width: (window.innerWidth / 100) * getWidth(this.props.width, !!this.props.coverLetter, this.props.height),
    isSyncing: client.cache.readQuery({ query: GET_SAVING_STATUS }) === 'SAVING',
    variant: marvelEmitter.getActiveVariant('website_resume_experiment'),
    newSkillsOrders: '',
  };

  // url: /resumes or /cover-letters
  back = async (e) => {
    e.stopPropagation();
    const isAgent = getIsAgent();
    const { coverLetter, locale, resume, userRefetch, listResumes } = this.props;
    const baseURL = coverLetter
      ? getJobTrackRoute(this.props.host, 'coverletters').href
      : getJobTrackRoute(this.props.host, 'resumes').href;
    const url = isAgent ? `/app?template=dashboard` : baseURL;
    const basePath = coverLetter
      ? getJobTrackRoute(this.props.host, 'coverletters').as
      : getJobTrackRoute(this.props.host, 'resumes').as;
    const asPath = isAgent ? `/app/dashboard` : basePath;
    if (resume?.fromLinkedIn) await userRefetch();
    await listResumes.refetch();
    Push(`${url}`, locale, asPath);
  };

  repositionBlock = () => {
    const { updateBlockPosition, updateImmue, resume, coverLetter } = this.props;
    const data = resume || coverLetter;

    const customBlocks =
      data && data.blocks && data.blocks.length > 0
        ? data.blocks.map((b) => (b.type === 'CUSTOM' ? b.id : null)).filter((b) => b)
        : [];
    const customSkillsBlocks =
      data && data.blocks && data.blocks.length > 0
        ? data.blocks.map((b) => (b.type === 'CUSTOM_SKILLS_CATEGORY' ? b.id : null)).filter((b) => b)
        : [];
    const heights = getPreviewBlocksHeight(customBlocks, customSkillsBlocks);
    const updatedBlocks = handleBlockRepositioning(resume.blocks, heights, null, true, true);
    const newResume = replacedBlocks(resume, updatedBlocks.blocks);
    const blocks = extractBlockInfo(updatedBlocks.blocks);
    updateImmue(newResume);
    updateBlockPosition({
      variables: {
        resumeId: newResume.id,
        blocks: blocks,
      },
    });
  };

  componentDidMount() {
    window.addEventListener('resize', this.handlePreviewResize);
    const query = this.props.router.query;
    if (query?.optimizedResume && query?.optimizedResume == 'true') {
      setTimeout(() => {
        this.repositionBlock();
      }, 2500);
    }
    this.changeSavedStatus = client
      .watchQuery({
        query: GET_SAVING_STATUS,
      })
      .subscribe({
        next: ({ data }) => {
          this.setState({
            isSyncing: data.savingStatus === 'SAVING',
          });
        },
        error: console.log,
      });
  }

  componentDidUpdate(prevProps) {
    const {
      router: {
        query: { step },
      },
    } = this.props;

    const {
      router: {
        query: { step: prevStep },
      },
    } = prevProps;

    // this check is run to make sure the template preview size
    // is consistent between resume/cover-letter creation steps
    if ((step !== prevStep && step === 'finish') || prevStep === 'finish') {
      this.handlePreviewResize();
    }
  }

  componentWillUnmount() {
    this.changeSavedStatus.unsubscribe();
    window.removeEventListener('resize', this.handlePreviewResize);
  }

  handlePreviewResize = throttle(() => {
    this.setState({
      width: (window.innerWidth / 100) * getWidth(this.props.width, this.state.isCoverLetter, this.props.height),
    });
  }, 50);

  /**
   * To create a Cover Letter a user *must* go through the
   * create resume flow - that doesn't necessarily mean that
   * he/she'll get to the finalize step.
   * I'd show the Close button regardless.
   * */

  isCloseVisible = (step) => {
    const { coverLetter } = this.props;

    return step === 'start' && !coverLetter ? localStorage.getItem('resumedone:finalize-seen') : true;
  };

  goToReview = (disable) => () => {
    const {
      resume: { id: resumeId } = {},
      coverLetter: { id: coverLetterId } = {},
      locale,
      isCoverLetter,
    } = this.props;
    if (disable) return;
    const sourceId = resumeId || coverLetterId;
    const source = isCoverLetter ? 'cover-letter' : 'resume';
    const resumeData = {
      resumeId: sourceId,
      step: 'preview',
    };
    const coverLetterData = {
      coverLetterId: sourceId,
      view: 'preview',
      step: 'preview',
    };
    const data = isCoverLetter ? coverLetterData : resumeData;
    const path = `/${source}?${qs.stringify(data)}`;
    Push(`${path}`, locale, `/${source}/${sourceId}/preview`);
  };

  handleGenerateWebsite = () => {
    const { source: { hasWebsite } = {}, trackEvent } = this.props;
    if (hasWebsite) return;
    trackEvent('create_new_website', { pageView: 'generator_view' });
  };

  render() {
    const {
      router: {
        query: { step },
      },
      resume,
      coverLetter,
      height,
      showVideoPreview,
      t,
      trackEvent,
      context: { state },
      nocloseIcon,
    } = this.props;
    const { width, isCoverLetter } = this.state;
    const data = resume || coverLetter;
    const RESUME_WIDTH_VW = getWidth(this.props.width, isCoverLetter, height);

    const { id, currentStep, hasWebsite } = data;
    const qsWebsite = {
      source: id,
    };

    const editWebsite = {
      href: `/sites/${id}`,
    };

    const createWebsite = {
      href: `/onboardingweb?${qs.stringify(qsWebsite)}`,
      as: '/onboardingweb',
    };

    const websiteCTAProps = hasWebsite ? editWebsite : createWebsite;
    const showWebsiteFeature = false; /*variant === 'website'*/

    const handleChange = (e) => {
      const {
        context: {
          dispatch,
          state: { toggle },
        },
        updateBlockPosition,
        updateImmue,
      } = this.props;
      dispatch({ type: 'UPDATE_STATE', name: 'prevtoggleState', payload: toggle.active ? 'active' : 'deactive' });
      if (e.target.checked) {
        const obj = {
          active: true,
          deActive: false,
        };
        if (!toggle.active) {
          const customBlocks =
            data && data.blocks && data.blocks.length > 0
              ? data.blocks.map((b) => (b.type === 'CUSTOM' ? b.id : null)).filter((b) => b)
              : [];
          const customSkillsBlocks =
            data && data.blocks && data.blocks.length > 0
              ? data.blocks.map((b) => (b.type === 'CUSTOM_SKILLS_CATEGORY' ? b.id : null)).filter((b) => b)
              : [];
          const heights = getPreviewBlocksHeight(customBlocks, customSkillsBlocks);
          const updatedBlocks = handleBlockRepositioning(resume.blocks, heights, null, true, true);
          const newResume = replacedBlocks(resume, updatedBlocks.blocks);
          const blocks = extractBlockInfo(updatedBlocks.blocks);
          updateImmue(newResume);
          updateBlockPosition({
            variables: {
              resumeId: newResume.id,
              blocks: blocks,
            },
          });
        }
        dispatch({ type: 'UPDATE_STATE', name: 'toggle', payload: obj });
      } else {
        const obj = {
          active: false,
          deActive: true,
        };
        dispatch({ type: 'UPDATE_STATE', name: 'toggle', payload: obj });
      }
      const name = e.target.checked ? 'active' : 'deactive';
      trackEvent('block_reposition_toggle_triger', { selected: name });

      if (state.isTooltipClose && state.prevtoggleState === 'deactive') {
        dispatch({ type: 'UPDATE_STATE', name: 'isTooltipOpen', payload: true });
        setTimeout(() => {
          dispatch({ type: 'UPDATE_STATE', name: 'isTooltipOpen', payload: false });
        }, 4000);
      }
    };

    return (
      <Fragment>
        {this.isCloseVisible(step) && !nocloseIcon && (
          <Close onClick={this.back}>
            <SvgIcon.CloseDark width="18px" height="18px" viewBox="0 0 18 18" />
          </Close>
        )}
        <Resume
          id="resume-preview"
          onClick={this.goToReview(isCoverLetter ? false : true)}
          RESUME_WIDTH_VW={RESUME_WIDTH_VW}
          showVideoPreview={showVideoPreview}
          isCoverLetter={isCoverLetter}
        >
          <Fragment>
            <PDFViewWrapper>
              <PDFViewer
                source={data}
                template={data.settings.template}
                width={width}
                updatesCount={data.updatesCount}
                isPreview
                updateImmue={this.props.updateImmue}
              />
            </PDFViewWrapper>
          </Fragment>
          {isCoverLetter ? (
            <Flex alignItems="center" justifyContent="center" id="btn-enlarge">
              <FullSizeIcon>
                <SvgIcon.FullSizeLarge width={26} height={26} viewBox="0 0 26 26" />
              </FullSizeIcon>
            </Flex>
          ) : (
            <IconWrapper className="preview-button" onClick={this.goToReview(false)}>
              <SvgIcon.FullSizeLarge width={26} height={26} viewBox="0 0 26 26" id="design-v1-icon" />
              <PreviewArrowIcon id="design-v2-icon" />
            </IconWrapper>
          )}
          {state.isTooltipOpen && <ToggleDeactiveModal toggleRef={this.toggleRef} />}
        </Resume>
        {(state.isClosed || state.isTooltipClose) && (
          <RespositionWrapper
            ref={this.toggleRef}
            direction="column"
            alignItems="center"
            wraperwidth={width}
            className="ai-button"
          >
            <RepositionTitle dangerouslySetInnerHTML={{ __html: t('ai_reposition') }} />
            <Label>
              <Input type="checkbox" onChange={handleChange} checked={state.toggle.active} />
              <Switch />
            </Label>
          </RespositionWrapper>
        )}
      </Fragment>
    );
  }
}

Preview.displayName = 'Preview';

const RepositionTitle = styled.div`
  font-family: ${({ theme }) => theme.font.family.webSiteSemiBold};
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  @media (min-width: 1000px) {
    font-size: 11px;
  }
  @media (min-width: 1440px) {
    font-size: 13px;
  }
`;
const RespositionWrapper = styled(Flex)`
  gap: 10px;
  position: absolute;
  top: 114px;
  right: 1%;
  position: fixed;
  margin-left: -4vw;
  z-index: 1005;
  width: calc(40vw - ${(p) => p.wraperwidth}px);
  display: none;

  @media (max-width: 1970px) {
    width: calc(41vw - ${(p) => p.wraperwidth}px);
  }

  @media (max-width: 1920px) {
    width: calc(42vw - ${(p) => p.wraperwidth}px);
  }

  @media (max-width: 1850px) {
    width: calc(43vw - ${(p) => p.wraperwidth}px);
  }

  @media (max-width: 1600px) {
    width: calc(42vw - ${(p) => p.wraperwidth}px);
  }

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      margin-right: -4vw;
      margin-left: 0;
      right: auto;
      left: 1%;
      width: calc(40vw - ${(p) => p.wraperwidth}px);
    `}
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const Switch = styled.div`
  position: relative;
  width: 35px;
  height: 20px;
  background: #c4c4c7;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: 50%;
    left: 0px;
    background: white;
    transform: translate(0, -50%);
  }
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;
  cursor: pointer;

  &:checked + ${Switch} {
    background: #1688fe;

    &:before {
      transform: translate(15px, -50%);
    }
  }
`;

const PDFViewWrapper = styled.div`
  overflow: hidden;
  border-radius: ${(p) => p.theme.general.borderRadius};
  direction: ltr;
`;

const WebsiteButton = styled.div`
  padding: 11px 24px;
  border-radius: 5px;
  border: solid 2px #1688fe;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.21px;
  text-align: center;
  color: #1688fe;
  text-transform: capitalize;
  cursor: pointer;
  position: absolute;
  right: -58px;
  top: 125px;
  transform: rotate(270deg);
`;

const FullSizeIcon = styled((p) => <Flex alignItems="center" justifyContent="center" {...p} />)`
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: #1688fe;
  color: white;
  transform: scale(0);
  cursor: pointer;
  z-index: 99999999;
`;

const Resume = styled.div`
  background: ${({ showVideoPreview }) => (showVideoPreview ? 'transparent' : 'white')};
  border-radius: ${(p) => p.theme.general.borderRadius};
  width: ${({ RESUME_WIDTH_VW }) => RESUME_WIDTH_VW}vw;
  margin: 20px auto;
  &:hover {
    ${FullSizeIcon} {
      top: 50%;
      left: 50%;
      right: 50%;
      transform: translate(0, -50%);
    }
  }
  display: flex;

  ${({ isCoverLetter }) =>
    isCoverLetter &&
    css`
      cursor: pointer;
      position: relative;
    `}
`;

const Close = styled((p) => <Button unstyled {...p} />)`
  position: fixed;
  cursor: pointer;
  top: 20px;
  right: 30px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  z-index: 10;
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      left: 30px;
      right: auto;
    `}
`;

const IconWrapper = styled.div`
  height: 3vw;
  width: 3vw;
  position: fixed;
  top: 20px;
  margin-left: -4vw;
  background-color: #1688fe;
  border-radius: 0.2vw;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.29);
  z-index: 100;
  svg {
    margin: auto;
    height: 1.3vw;
    width: 1.3vw;
  }
  cursor: pointer;
  display: none;
  #design-v2-icon {
    display: none;
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      margin-right: -4vw;
      margin-left: 0;
    `}
`;

export default withBlockResposition(Preview);
