import React, { createContext, useContext, useEffect, useState } from 'react';
import ct from 'countries-and-timezones';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import randomWeight from 'random-weight';

import AccountSkeleton from '/imports/dashboard/ui/skeleton/AccountSkeleton';
import CheckoutSkeleton from '/imports/checkout/ui/skeleton/CheckoutSkeleton';
import DashboardSkeleton from '/imports/dashboard/ui/skeleton/DashboardSkeleton';
import env from '/env';
import { getCountry } from '/imports/checkout/api/utils';
import { getCurrency, getRecordsByBase, EXPLICITY_SET_ZAR_CURRENCY, getLocation } from '/lib/helpers';
import { getLBRules, getPlans } from '/imports/checkout/api/helpers';
import intlHook from '/imports/core/api/useIntl';
import LayoutSkeleton from '/imports/job-tracking/ui/skeleton/layoutSkeleton';
import Loading from '/imports/core/ui/components/Loading';
import { useResponsive } from '/imports/core/api/responsiveContext';
import publicIp from 'public-ip';

const isProd = env.NODE_ENV === 'production';

const Loaders = {
  dashboard: <DashboardSkeleton />,
  account: <AccountSkeleton />,
  checkout: <CheckoutSkeleton />,
  layout: <LayoutSkeleton />,
  null: null,
};

const hideModal = 'hideModal';
const cardsPaddle = 'Weight Paddle';
const cardsFastSpring = 'Weight Fast Spring';
const cardsSolid = 'Weight Solid';
const cardsStripe = 'Weight Stripe';
const cardsMollie = 'Weight Mollie';
const amexPaddle = 'Weight Amex Paddle';
const amexStripe = 'Weight Amex Stripe';
const amexFastSpring = 'Weight Amex Fast Spring';
const amexMollie = 'Weight Amex Mollie';
const amexSolid = 'Weight Amex Solid';
const mcPaddle = 'Weight MC Paddle';
const mcStripe = 'Weight MC Stripe';
const mcFastSpring = 'Weight MC Fast Spring';
const mcMollie = 'Weight MC Mollie';
const mcSolid = 'Weight MC Solid';
const visaPaddle = 'Weight Visa Paddle';
const visaStripe = 'Weight Visa Stripe';
const visaFastSpring = 'Weight Visa Fast Spring';
const visaMollie = 'Weight Visa Mollie';
const visaSolid = 'Weight Visa Solid';
const providerIfHidden = 'providerIfModalHidden';
const showPaymentDisclaimer = 'Show Payment Disclaimer';
const showTermsCheckbox = 'Terms';
const hideOneYearCheckbox = 'hide1year';
const showTrustPilotCheckbox = 'Show Trust Pilot';

const getRandomWeight = (weightStripe, weightPaddle, weightFastSpring, weightMollie, weightSolid) => {
  if (!weightPaddle && !weightStripe && !weightFastSpring && !weightMollie && !weightSolid) return 6;
  return randomWeight(
    [
      {
        version: 1, // Stripe
        weight: weightStripe,
      },
      {
        version: 4, // Paddle
        weight: weightPaddle,
      },
      {
        version: 5, // Solid
        weight: weightSolid,
      },
      {
        version: 6, //fastSpring
        weight: weightFastSpring,
      },
      {
        version: 7, //Mollie
        weight: weightMollie,
      },
    ],
    (i) => i.weight,
  ).version;
};

const getBillingVersionByProviderName = (providerName) => {
  switch (providerName) {
    case 'stripe':
      return 1;
    case 'paddle':
      return 4;
    case 'solid':
      return 5;
    case 'mollie':
      return 7;
    case 'fastspring':
    default:
      return 6;
  }
};

const billingVersionByCard = (data, cardType) => {
  const cardsStripeWeight = data.weightStripe || 0;
  const cardsPaddleWeight = data[cardsPaddle] || 0;
  const cardsFSWeight = data.weightFastSpring || 0;
  const cardsMollieWeight = data.weightMollie || 0;
  const cardsSolidWeight = data.weightSolid || 0;
  switch (cardType) {
    case 'americanexpress':
      return getRandomWeight(
        data.weightAmexStripe,
        data.weightAmexPaddle,
        data.weightAmexFastSpring,
        data.weightAmexMollie,
        data.weightAmexSolid,
      );
    case 'mastercard':
      return getRandomWeight(
        data.weightMCStripe,
        data.weightMCPaddle,
        data.weightMCFastSpring,
        data.weightMCMollie,
        data.weightMCSolid,
      );
    case 'visa':
      return getRandomWeight(
        data.weightVisaStripe,
        data.weightVisaPaddle,
        data.weightVisaFastSpring,
        data.weightVisaMollie,
        data.weightVisaSolid,
      );
    default:
      return getRandomWeight(cardsStripeWeight, cardsPaddleWeight, cardsFSWeight, cardsMollieWeight, cardsSolidWeight);
  }
};

const getBillingVersion = (cardType, localRule) => {
  // setup stripe for staging
  if (env.NODE_ENV !== 'production') {
    return 1;
  }
  const rule = localRule;
  if (rule && rule?.hideModal) {
    const cardsStripeWeight = rule.weightStripe || 0;
    const cardsPaddleWeight = rule[cardsPaddle] || 0;
    const cardsFSWeight = rule.weightFastSpring || 0;
    const cardsMollieWeight = rule.weightMollie || 0;
    const cardsSolidWeight = rule.weightSolid || 0;
    if (
      cardsStripeWeight > 0 ||
      cardsPaddleWeight > 0 ||
      cardsFSWeight > 0 ||
      cardsMollieWeight > 0 ||
      cardsSolidWeight > 0
    ) {
      const version = billingVersionByCard(rule, '');
      if (version) return version;
    }
    return getBillingVersionByProviderName(rule?.providerIfModalHidden);
  }

  if (rule && !rule?.hideModal) {
    const version = billingVersionByCard(rule, cardType);
    if (version) return version;
  }

  return 6;
};
const BillingContext = createContext();

// Provider component that wraps your app and makes the apollo client
// available to any child component that calls useBilling().
export function BillingProvider({
  children,
  value,
  specialPlanId,
  withLoading = true,
  onlyForSpecial = false,
  loadingComponent = null,
  rule,
}) {
  const billing = useBillingProvider(value, specialPlanId, onlyForSpecial, rule);

  if (billing.isLoading) {
    if (withLoading) {
      return loadingComponent ? Loaders[loadingComponent] : <Loading />;
    }
    return null;
  }

  return <BillingContext.Provider value={billing}>{children}</BillingContext.Provider>;
}

function selectUrlBasedOnPercentage(urlsWithPercentages, trustPilotRulesException, host) {
  const country = getCountry();
  if (!urlsWithPercentages) return;
  if (trustPilotRulesException && Array.isArray(trustPilotRulesException)) {
    for (let exception of trustPilotRulesException) {
      if (exception.domain === host && exception.country.map((c) => c.toUpperCase()).includes(country.toUpperCase())) {
        return exception.URL;
      }
    }
  }
  let totalPercentage = urlsWithPercentages.reduce((acc, item) => acc + item.Percentage, 0);
  if (totalPercentage !== 1) {
    return 'https://www.trustpilot.com/review/cv-lite.com';
  }
  let random = Math.random();
  let cumulative = 0;
  for (let i = 0; i < urlsWithPercentages.length; i++) {
    cumulative += urlsWithPercentages[i].Percentage;
    if (random <= cumulative) {
      return urlsWithPercentages[i].URL;
    }
  }
  return urlsWithPercentages[urlsWithPercentages.length - 1].URL;
}

BillingProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.object,
  specialPlanId: PropTypes.string,
  withLoading: PropTypes.bool,
  onlyForSpecial: PropTypes.bool,
  loadingComponent: PropTypes.string,
  rule: PropTypes.object,
};

// Provider hook that creates store object and handles state
function useBillingProvider(client, specialPlanId, onlyForSpecial, intialRule = null) {
  const { host } = useResponsive();
  const { locale } = intlHook();
  const [currency, setCurrency] = useState(getCurrency(host));
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [trustPilotUrl, setTrustPilotUrl] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(specialPlanId ? specialPlanId : null);
  const [card, setCard] = useState(null);
  const [rule, setRule] = useState(intialRule);
  const [hideModalPreview, setHideModalPreview] = useState(true);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [hideOneYear, setHideOneYear] = useState(false);
  const [showTrustPilot, setShowTrustPilot] = useState(false);
  const country = getCountry();

  const fetchData = async (IP) => {
    const countryCode = await getLocation(IP || '127.0.0.1');
    const currency = getCurrency(host, countryCode);
    setCurrency(currency);
    return currency;
  };

  // Fetch all billing plans for the selected currency.
  useEffect(() => {
    let isMounted = true;
    let newRule = null;

    const fetchPlansAndRules = async () => {
      if (onlyForSpecial) {
        setIsLoading(false);
        return;
      }
      try {
        setIsLoading(true);
        let updatedCurrency = getCurrency(host);
        if (EXPLICITY_SET_ZAR_CURRENCY.includes(host)) {
          const IP = await publicIp.v4();
          const data = await fetchData(IP);
          updatedCurrency = data || updatedCurrency;
        }
        if (!updatedCurrency) {
          setIsLoading(false);
          return;
        }
        const plans = await getPlans(updatedCurrency);
        if (isMounted) {
          setPlans(plans);
          if (!newRule) {
            const rule = await getLBRules(host, country);
            setRule(rule);
            const trustPilotRules = await getRecordsByBase('Load Balancing Trust Pilot');
            const trustPilotRulesException = await getRecordsByBase('Trust Pilot Balancer Exceptions');
            const url = selectUrlBasedOnPercentage(trustPilotRules, trustPilotRulesException, host);
            if (localStorage) localStorage.setItem('trust_pilot_url', url);
            if (url) setTrustPilotUrl(url);
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };
    fetchPlansAndRules();
    return () => {
      isMounted = false;
    };
  }, [host, specialPlanId, onlyForSpecial]);

  useEffect(() => {
    if (!!rule) {
      setHideModalPreview(rule ? !!rule?.hideModal : true);
      setShowDisclaimer(rule ? !!rule?.showPaymentDisclaimer : false);
      setShowTerms(rule ? !!rule?.terms : false);
      setHideOneYear(rule ? !!rule?.hide1year : false);

      setShowTrustPilot(rule ? !!rule?.showTrustPilot : false);
      setIsLoading(false);
    }
  }, [rule]);

  const billingVersion = getBillingVersion(card, rule);
  // Return store objects and store methods
  return {
    isLoading,
    client,
    currency,
    setCurrency,
    plans,
    currentPlan,
    setCurrentPlan,
    billingVersion,
    setSelectedCard: setCard,
    card: hideModalPreview || card,
    showDisclaimer,
    showTerms,
    hideOneYear,
    showTrustPilot,
    trustPilotUrl,
  };
}
// Decorator HOC for legacy class components
// that adds billing context as props.
export const withBilling = (Component) =>
  class Wrapper extends React.Component {
    render() {
      return <BillingContext.Consumer>{(state) => <Component {...this.props} {...state} />}</BillingContext.Consumer>;
    }
  };

// Hook for child components to get the context object
// and re-render when it changes.
const BillingContextComponent = () => useContext(BillingContext);
export default BillingContextComponent;
