import { isSkillsTagsWithMoreSpaceActive } from 'imports/generator/api/helpers';
import styled, { css } from 'styled-components';
// BlockSection is a group of related blocks.
// For example, a "Education" or "Employment" section.
const BlockSection = styled.div`
  break-inside: avoid;
  page-break-inside: avoid;
  z-index: 99;
  ${({ theme, left, isDragging, isBreakable, $blockType, $isTag }) => css`
    ${isDragging &&
    css`
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3);
      border: solid 1px #bccbe5;
    `}
    padding: ${theme.getPaddingMargin({ right: left ? 36 : 24, left: left ? 36 : 24 })};
    ${isSkillsTagsWithMoreSpaceActive(theme) &&
    left == true &&
    $blockType == 'SKILLS' &&
    $isTag == true &&
    css`
      padding: ${theme.getPaddingMargin({ right: left ? 7 : 24, left: left ? 36 : 24 })};
    `}

    ${isBreakable &&
    css`
      page-break-inside: auto !important;
      break-inside: auto !important;
    `}
  `}
`;

export default BlockSection;
