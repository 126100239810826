import React from 'react';
import styled, { css } from 'styled-components';

import ButtonAtom from '/imports/core/ui/atoms/Button';
import DownloadButtonAtom from '/imports/pdf/core/ui/components/DownloadButton';
import EditableTitleAtom from '/imports/core/ui/atoms/EditableTitle';
import Text from '/imports/core/ui/atoms/Text';

export const ItemCont = styled.div`
  width: 50%;
  padding: 15px;
  position: relative;
  min-width: 300px;
  max-width: 550px;
  > div {
    align-items: stretch;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    padding: 15px;
  }
  ${({ theme: { isJobTrack } }) => css`
    ${isJobTrack &&
    css`
      width: auto;
      max-width: unset;
      min-width: unset;
      padding: 0 45px 40px;
    `}
  `}
`;

export const containerCustomCss = css`
  margin-left: 0;
`;

export const dropdownMenuCustomCss = css`
  top: 24px;
  left: -2px;
  width: 130px;

  > div {
    border-top-right-radius: 5px;
    padding: 8px 0;
    a {
      font-family: TTCommons;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.4px;
      display: flex;
      align-items: center;
      padding: 8px 10px;
      svg {
        width: 12px;
        height: 15px;
      }
      span {
        padding-right: 6px;
      }
    }
  }
`;

export const DateText = styled((p) => <Text {...p} />)`
  margin-top: 0;
  margin-bottom: 21px;
  color: #989caf;
  font-size: 12px;
  line-height: normal;
  font-weight: 500;
  letter-spacing: normal;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  padding-right: 0;
`;

export const EditableTitle = styled(EditableTitleAtom)`
  position: relative;
  margin: -7px 0 5px -13px;
  font-size: 19px;
  line-height: 23px;
  font-weight: 600;
  letter-spacing: 0.08em;
  text-align: center;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #282b32;
  background: none;
  border: none;

  button {
    opacity: 1;
    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

export const TitleWrapper = styled.span`
  margin-left: 12px;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      margin-right: 12px;
      margin-left: auto;
    `}
`;

export const TemplateCont = styled.div`
  position: relative;
  background: white;
  border-radius: ${({ theme }) => theme.general.borderRadius};
  cursor: pointer;
  background-image: url(${(p) => p.template});
  padding-top: 141.702128%;
  padding-bottom: 20px;
  margin-bottom: 18px;
  margin-inline: -5px;
  background-repeat: no-repeat;
  background-size: cover;
  &:hover {
    box-shadow: 0 23px 23px 0 rgba(28, 29, 50, 0.16);
  }
  ${({ resumeItem }) =>
    resumeItem &&
    css`
      margin-bottom: 0;
      border-radius: 4px 4px 0 0;
    `}
  ${({ theme: { isJobTrack } }) =>
    isJobTrack &&
    css`
      border-radius: unset;
      width: 100%;
      @media (min-width: 992px) {
        padding-top: 144.702128%;
      }
    `}
`;

export const buttonStyle = css`
  width: max-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 10px !important;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;

  span,
  svg {
    line-height: 1;
  }

  a {
    line-height: 1.4;
  }

  svg > path,
  rect,
  g {
    fill: #282b32;
  }

  ${(p) =>
    p.saveTxt &&
    css`
      font-size: ${({ theme }) => theme.font.size.small};
      color: ${({ theme }) => theme.colors.gray.regular};
    `}

  &:hover {
    color: #0087c1;
    background: transparent;
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

export const Button = styled((p) => <ButtonAtom unstyled {...p} />)`
  ${({ isDuplicate }) =>
    isDuplicate &&
    css`
      &:disabled {
        background: none !important;
        color: gray !important;
        fill: gray;
        svg > path,
        rect,
        g {
          fill: gray;
        }
        .copy-icon path:first-of-type {
          stroke: gray;
        }
        &:hover {
          svg > path,
          rect,
          g {
            fill: gray;
          }
          .copy-icon path:first-of-type {
            stroke: gray;
          }
        }
      }
    `}
  ${buttonStyle};

  &:hover {
    svg > path,
    rect,
    g {
      fill: #0087c1;
    }

    .copy-icon path:first-of-type {
      stroke: #0087c1;
    }
  }
`;

export const DownloadButton = styled((p) => <DownloadButtonAtom {...p} />)`
  background: transparent;
  border: 0;
  border-radius: 0;
  ${buttonStyle};

  a {
    position: relative;
    color: #282b32;
    padding: 0;

    &:hover {
      color: #0087c1;
      svg > path {
        fill: #0087c1;
      }
    }
  }
`;

export const Controls = styled.div`
  padding: 20px 0 0 35px;
  ${({ theme }) => theme.max('md')`
     padding: 21px 0 0 10px;
  `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      padding: 20px 35px 0 0;

      ${({ theme }) => theme.max('md')`
        padding: 21px 10px 0 0;
      `}
    `}
`;
