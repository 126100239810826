import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import debounce from 'lodash/debounce';

import GeneratorValidatedInput from '/imports/core/ui/atoms/GeneratorValidatedInput';
import MailCheck from '/imports/generator/ui/components/MailCheck';
import { useAccount } from '/imports/core/api/accounts/accountContext';
import { getInputName } from '/imports/generator/api/helpers';
import useIntl from 'imports/core/api/useIntl';
import InputCheckboxAutoSave from 'imports/core/ui/atoms/InputCheckBoxAutoSave';
import {
  blockDetailImmutableUpdate,
  blockItemImmutableUpdateSwitch,
} from 'imports/generator/api/apollo/client/helpers';
import { UPDATE_BLOCK_FIELD, UPDATE_COVER_LETTER_DETAIL } from 'imports/generator/api/apollo/client/mutations';
import { getGender } from 'lib/helpers';
import { useMutation } from 'react-apollo';

const GeneratorInputWrap = (props) => {
  const {
    value,
    validators,
    placeholderSlug,
    placeholder,
    suggestion = false,
    variables,
    showCheckbox,
    resume,
    type,
    updateImmue,
    label,
    name,
    isCoverLetter,
    coverLetter,
    lang,
    translationSlugAdditionalFlags,
  } = props;

  const {
    trigger,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();
  const { currentUser } = useAccount();
  const { t } = useIntl();
  const [showSuggestion, setShowSuggestion] = useState(suggestion);
  const fieldName = variables?.field || props?.name;
  const inputName = getInputName(fieldName, variables);
  const oldValueRef = useRef(null);
  const activeBlockCheckbox = resume && resume?.blocks?.find((blocks) => blocks?.type === type);
  const [updateCoverLetter] = useMutation(UPDATE_COVER_LETTER_DETAIL);
  useEffect(() => {
    const updateValue = async () => {
      if (value?.trim() !== '' && value !== oldValueRef.current) {
        setValue(inputName, value);
        oldValueRef.current = value;
        await trigger(inputName);
      }
    };
    updateValue();
  }, [value, inputName, setValue, trigger]);

  const handleBlur = (e) => {
    if (!errors[inputName]) {
      setShowSuggestion(true);
    }
    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  useEffect(() => {
    if (isCoverLetter && fieldName === 'firstName' && coverLetter.details.userGender === null) {
      debouncedUpdateGenderCoverLetter(value);
    }
  }, []);

  const debouncedUpdateGenderCoverLetter = useCallback(
    debounce(async (value) => {
      const resp = await getGender(value);
      if (resp) {
        const options = {
          variables: {
            docId: variables.docId,
            path: 'details.userGender',
            value: resp,
          },
          context: {
            client: 'coverLetter',
          },
        };
        updateCoverLetter(options);
      }
    }, 500),
    [updateCoverLetter, variables.docId],
  );

  const handleChange = async (e) => {
    const isValid = await trigger(inputName);
    setValue(inputName, e.target.value);
    if (isValid) {
      props.onSave(e);
    }
    if (isCoverLetter && name === 'firstName') {
      debouncedUpdateGenderCoverLetter(e.target.value);
    }
  };

  const handleFocus = () => {
    setShowSuggestion(false);
  };

  const changeEmail = (data) => () => {
    setValue(inputName, data.full);
    props.onSave({ target: { value: data.full } });
  };

  const handleError = () => {
    setShowSuggestion(false);
  };

  return (
    <Controller
      control={control}
      rules={validators || {}}
      name={inputName}
      render={({ field }) => (
        <GeneratorValidatedInput
          {...props}
          field={field}
          errors={errors}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          placeholder={placeholderSlug && t(placeholderSlug) ? t(placeholderSlug) : placeholder}
          suggested={suggestion}
          errorCallback={handleError}
        >
          {suggestion && (!currentUser || !currentUser.email) && showSuggestion && (
            <MailCheck email={value} onChange={changeEmail} />
          )}
          {showCheckbox && (
            <InputCheckboxAutoSave
              key={`checkbox-${name}`}
              mutation={UPDATE_BLOCK_FIELD}
              variables={{
                docId: resume?.id,
                blockId: variables.blockId,
                field: name,
                needUpdate: true,
              }}
              label={t(translationSlugAdditionalFlags)}
              value={activeBlockCheckbox[name]}
              name={name}
              optimisticResponse={blockItemImmutableUpdateSwitch(updateImmue)(resume?.id, variables.blockId, name)}
            />
          )}
        </GeneratorValidatedInput>
      )}
    />
  );
};

GeneratorInputWrap.propTypes = {
  value: PropTypes.string,
  validators: PropTypes.object,
  onSave: PropTypes.func,
  placeholderSlug: PropTypes.string,
  placeholder: PropTypes.string,
  suggestion: PropTypes.bool,
};

export default GeneratorInputWrap;
