import capitalize from 'lodash/capitalize';
import styled, { css } from 'styled-components';
import { convertEndDate, displayTemplateTitle } from '/imports/generator/api/helpers';
import { degreeOptions } from '/imports/generator/api/constants';
import {
  findBlockByItemId,
  getBlockHideLevel,
  getClickToEditProps,
  getCustomBlockHideLevel,
  getSkillsTag,
} from '/imports/pdf/core/api/helpers';
import { LANGUAGE_PERCENTAGE_MAP, SKILL_PERCENTAGE_MAP } from '/imports/pdf/core/api/constants';
import ListItemDisplay from '/imports/pdf/budapest/ui/components/ListItemDisplay';
import PercentageDisplay from '/imports/pdf/budapest/ui/components/PercentageDisplay';
import TagDisplay from '/imports/pdf/budapest/ui/components/TagDisplay';
import PersonalDetailsDisplay from '/imports/pdf/budapest/ui/components/PersonalDetailsDisplay';
import ProfessionalSummary from '/imports/pdf/budapest/ui/components/ProfessionalSummary';
import RangeActivityDisplay from '/imports/pdf/budapest/ui/components/RangeActivityDisplay';
import ReferenceItem from '/imports/pdf/budapest/ui/components/ReferenceItem';
import Text from '/imports/pdf/budapest/ui/atoms/Text';
import EditRedirectionWrapper from 'imports/pdf/core/ui/components/EditRedirectionWrapper';

const TextCont = styled(Text)`
  text-transform: uppercase;
  ${({ left, theme: { color, setGenderStyle } }) =>
    left &&
    css`
      color: var(--light-values-white);
      ${color &&
      color === 'black' &&
      setGenderStyle('female')`
          color: #bcbec0;
        `}
    `}
`;

export default {
  CUSTOM: {
    renderItem({ item, source, last, left }) {
      if (!item.fields) return null;
      return (
        <RangeActivityDisplay
          key={item._id}
          itemId={item.id}
          title={item.fields.title}
          city={item.fields.city}
          description={item.fields.description}
          startDate={item.fields.startDate}
          endDate={item.fields.endDate}
          hideDates={item.fields.hideDates}
          current={item.fields.current}
          hideStartDate={item.fields.hideStartDate}
          last={!!last}
          language={source.settings.language}
          font={source.settings.contentFont}
          left={!!left}
        />
      );
    },
  },
  COURSES: {
    getItemTitle(item) {
      const { course } = item.fields || {};
      return course;
    },
    renderItem({ item, source, last, left }) {
      if (!item.fields) return null;
      const { course, institution } = item.fields || {};
      return (
        <RangeActivityDisplay
          key={item._id}
          itemId={item.id}
          title={left ? course : this.getItemTitle(item)}
          subtitle={institution}
          description={item.fields.description}
          startDate={item.fields.startDate}
          endDate={item.fields.endDate}
          current={item.fields.current}
          hideStartDate={item.fields.hideStartDate}
          last={!!last}
          language={source.settings.language}
          left={!!left}
          shorterList={left ? 0 : 1}
          font={source.settings.contentFont}
          fieldsName={{ title: 'course', subtitle: 'institution' }}
        />
      );
    },
  },
  EXTRA_CURRICULAR: {
    getItemTitle(item) {
      const { title, employer, city } = item.fields || {};
      return displayTemplateTitle(displayTemplateTitle(title, employer, ' at '), city, ', ');
    },
    renderItem({ item, source, last, left }) {
      if (!item.fields) return null;
      return (
        <RangeActivityDisplay
          key={item._id}
          itemId={item.id}
          title={item.fields.title}
          city={item.fields.city}
          employer={item.fields.employer}
          description={item.fields.description}
          hideStartDate={item.fields.hideStartDate}
          current={item.fields.current}
          startDate={item.fields.startDate}
          endDate={item.fields.endDate}
          last={!!last}
          language={source.settings.language}
          left={!!left}
          font={source.settings.contentFont}
        />
      );
    },
  },
  INTERNSHIPS: {
    getItemTitle(item) {
      const { title, employer, city } = item.fields || {};
      return displayTemplateTitle(displayTemplateTitle(title, employer, ' at '), city, ', ');
    },
    renderItem({ item, source, last, left }) {
      if (!item.fields) return null;
      return (
        <RangeActivityDisplay
          key={item._id}
          itemId={item.id}
          title={item.fields.title}
          employer={item.fields.employer}
          city={item.fields.city}
          description={item.fields.description}
          startDate={item.fields.startDate}
          endDate={item.fields.endDate}
          current={item.fields.current}
          last={!!last}
          language={source.settings.language}
          left={!!left}
          font={source.settings.contentFont}
        />
      );
    },
  },
  HOBBIES: {
    icon: 'e925',
    renderItem({ item, source, left }) {
      const {
        settings: { language },
      } = source;
      if (!item.fields) return null;
      return (
        <EditRedirectionWrapper
          {...getClickToEditProps(item.id, { description: item.fields.description })}
          isTextAreaDescription
        >
          <TextCont left={!!left}>{item.fields.description}</TextCont>
        </EditRedirectionWrapper>
      );
    },
  },
  LANGUAGES: {
    getItemTitle(item) {
      const { language = '' } = item.fields || {};
      return `${language}`;
    },
    renderItem({ item, source, left }) {
      if (!item.fields?.language) return null;
      return (
        <PercentageDisplay
          key={item._id}
          itemId={item.id}
          title={this.getItemTitle(item)}
          percentage={LANGUAGE_PERCENTAGE_MAP[item.fields.languageLevel]}
          language={source.settings.language}
          hideStripe={getBlockHideLevel(source, 'LANGUAGES')}
          left={!!left}
          fieldsName={{ percentage: 'languageLevel', title: 'language' }}
        />
      );
    },
  },
  REFERENCES: {
    getItemTitle(item) {
      const { fullName, company } = item.fields || {};
      return displayTemplateTitle(fullName, company, ' from ');
    },
    renderItem({ item, left }) {
      if (!item.fields) return null;
      return (
        <ReferenceItem
          key={item._id}
          itemId={item.id}
          title={item.fields.fullName}
          company={item.fields.company}
          email={item.fields.email}
          phone={item.fields.phone}
          left={!!left}
          fieldsName={{ title: 'fullName' }}
        />
      );
    },
  },
  EMPLOYMENT: {
    getItemTitle(item) {
      const { title, employer, city } = item.fields || {};
      return displayTemplateTitle(displayTemplateTitle(title, employer, ' at '), city, ', ');
    },
    renderItem({ item, source, last, left, index, blockType }) {
      if (!item.fields) return null;

      return (
        <RangeActivityDisplay
          key={item._id}
          itemId={item.id}
          title={item.fields.title}
          employer={item.fields.employer}
          description={item.fields.description}
          city={item.fields.city}
          startDate={item.fields.startDate}
          endDate={item.fields.endDate}
          current={item.fields.current}
          last={!!last}
          language={source.settings.language}
          left={!!left}
          font={source.settings.contentFont}
          index={index}
          blockType={blockType}
        />
      );
    },
  },
  SKILLS: {
    getItemTitle(item) {
      const { skill, skillLevel } = item.fields || {};
      let res = capitalize(skillLevel);
      if (skill) return skill;
      return res;
    },
    renderItem({ item, source, left }) {
      if (!item.fields?.skill) return null;
      if (getSkillsTag(source, 'SKILLS')) {
        return <TagDisplay key={item._id} item={item} title={this.getItemTitle(item)} left={!!left} />;
      }
      return (
        <PercentageDisplay
          key={item._id}
          itemId={item.id}
          title={this.getItemTitle(item)}
          percentage={SKILL_PERCENTAGE_MAP[item.fields.skillLevel]}
          hideStripe={getBlockHideLevel(source, 'SKILLS')}
          left={!!left}
          fieldsName={{ title: 'skill' }}
        />
      );
    },
  },
  SOFT_SKILLS: {
    getItemTitle(item) {
      const { skill, skillLevel } = item.fields || {};
      let res = capitalize(skillLevel);
      if (skill) return skill;
      return res;
    },
    renderItem({ item, source, left }) {
      if (!item.fields?.skill) return null;
      return (
        <PercentageDisplay
          key={item._id}
          title={this.getItemTitle(item)}
          percentage={SKILL_PERCENTAGE_MAP[item.fields.skillLevel]}
          hideStripe={getBlockHideLevel(source, 'SOFT_SKILLS')}
          left={!!left}
        />
      );
    },
  },
  HARD_SKILLS: {
    getItemTitle(item) {
      const { skill, skillLevel } = item.fields || {};
      let res = capitalize(skillLevel);
      if (skill) return skill;
      return res;
    },
    renderItem({ item, source, left }) {
      if (!item.fields?.skill) return null;
      return (
        <PercentageDisplay
          key={item._id}
          title={this.getItemTitle(item)}
          percentage={SKILL_PERCENTAGE_MAP[item.fields.skillLevel]}
          hideStripe={getBlockHideLevel(source, 'HARD_SKILLS')}
          left={!!left}
        />
      );
    },
  },
  CUSTOM_SKILLS_CATEGORY: {
    getItemTitle(item) {
      const { skill, skillLevel } = item.fields || {};
      let res = capitalize(skillLevel);
      if (skill) return skill;
      return res;
    },
    renderItem({ item, source, left }) {
      if (!item.fields?.skill) return null;
      const allCustomBlock = source.blocks.filter((b) => b.type === 'CUSTOM_SKILLS_CATEGORY');
      const specificBlock = findBlockByItemId(allCustomBlock, item.id);
      return (
        <PercentageDisplay
          key={item._id}
          title={this.getItemTitle(item)}
          percentage={SKILL_PERCENTAGE_MAP[item.fields.skillLevel]}
          hideStripe={getCustomBlockHideLevel(source, specificBlock.id)}
          left={!!left}
        />
      );
    },
  },
  EDUCATION: {
    getItemTitle(item) {
      const { school, degree } = item.fields || {};
      return displayTemplateTitle(degree, school, ', ');
    },
    renderItem({ item, source, last, left, t }) {
      if (!item.fields) return null;
      const { degree } = item.fields || {};
      const { titleSlug } = degreeOptions.find((dg) => dg.title == degree) || {};
      return (
        <RangeActivityDisplay
          key={item._id}
          itemId={item.id}
          title={t(titleSlug, source.settings.language) || degree}
          employer={item.fields.school}
          city={item.fields.city}
          description={item.fields.description}
          endDate={convertEndDate(item.fields.endDate)}
          last={!!last}
          showOnlyYear
          language={source.settings.language}
          left={!!left}
          isEducation
          font={source.settings.contentFont}
          fieldsName={{ title: 'degree', employer: 'school' }}
        />
      );
    },
  },
  SOCIAL_LINKS: {
    getItemTitle(item) {
      const fields = item.fields || {};
      return fields.label || '(Not specified)';
    },
    renderItem({ item, source, left, type = 'resume' }) {
      if (!item.fields || !item.fields.label) return null;
      return (
        <ListItemDisplay
          key={item._id}
          itemId={item?.id}
          title={`${this.getItemTitle(item)}:`}
          src={item.fields.url}
          type={type}
          language={source.settings.language}
          left={!!left}
          fieldsName={{ title: 'label', src: 'url' }}
        />
      );
    },
  },
  DRIVING_LICENSE: {
    renderItem({ item, source, left, type = 'resume' }) {
      if (!item.fields || !item.fields.drivingLicense) return null;

      return (
        <ListItemDisplay
          key={item._id}
          itemId={item?.id}
          title={'driving_license_category'}
          value={item.fields.drivingLicense}
          type={type}
          language={source.settings.language}
          left={!!left}
          translateTitle
          fieldsName={{ title: null, value: 'drivingLicense' }}
        />
      );
    },
  },
  PERSONAL_DETAILS: {
    renderItem({ item, source, left, type = 'resume' }) {
      const fieldsList = ['dateOfBirth', 'nationality', 'visaStatus', 'maritalStatus'];

      if (!item.fields) return;

      return fieldsList.map((field, i) => {
        if (!item.fields[field]) return;

        return (
          <PersonalDetailsDisplay
            key={i}
            itemId={item.id}
            field={field}
            value={item.fields[field]}
            type={type}
            language={source.settings.language}
            left={!!left}
            fieldsName={{ value: field }}
            resume={source}
          />
        );
      });
    },
  },
  PUBLICATIONS: {
    renderItem({ item, source, last, left }) {
      if (!item.fields || !item.fields.publicationArticleTitle) return null;

      return (
        <RangeActivityDisplay
          key={item._id}
          itemId={item.id}
          title={item.fields.publicationArticleTitle}
          publicationTitle={item.fields.publicationTitle}
          endDate={item.fields.publicationDate}
          current={item.fields.current}
          last={!!last}
          language={source.settings.language}
          left={!!left}
          font={source.settings.contentFont}
          fieldsName={{ title: 'publicationArticleTitle', endDate: 'publicationDate' }}
        />
      );
    },
  },
  PROFESSIONAL_SUMMARY: {
    renderItem({ item, source, left }) {
      const {
        settings: { contentFont },
      } = source;

      if (!item.fields) return null;

      return (
        <EditRedirectionWrapper {...getClickToEditProps(item.id, { description: item.fields.description })}>
          <ProfessionalSummary font={contentFont} left={left} content={item.fields.description} />
        </EditRedirectionWrapper>
      );
    },
  },
};
