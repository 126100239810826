import { useEffect, useState, useRef, useMemo } from 'react';
import styled, { css } from 'styled-components';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import { useRouter } from 'next/router';
import Proptypes from 'prop-types';
import useIntl from '/imports/core/api/useIntl';
import { useResponsive } from '/imports/core/api/responsiveContext';
import useTracking from '/imports/core/hooks/useTracking';
import { CLICK_TO_EDIT_EXP_NEW_VERSION } from '/imports/generator/api/helpers.js';
import { useGeneralDesign } from '/imports/core/api/generalContext';
import {
  redirectToItemField,
  isWithClickToEditVariant,
  triggerRedirectionToDraftEditor,
  redirecToEditView,
  findViewByItemId,
} from '/imports/pdf/core/api/helpers';

const ClickToEditComponent = ({
  children,
  blockItemId,
  targetField = '',
  parentWrapperId = '',
  isTextAreaDescription = false,
  hidden = false,
  wrapperProps,
  styledSectionProps,
  buttonProps,
}) => {
  const fieldWithoutDnd = ['firstName', 'phone', 'email', 'address', 'title', 'lastName'];
  const { isMobile } = useResponsive();
  const { tooltipPreview, currentResume } = useGeneralDesign();
  const { trackEvent } = useTracking();
  const {
    query: { step, resumeId },
    asPath,
    events,
    route,
  } = useRouter();
  const { locale } = useIntl();
  const [disableFeature, setDisableFeature] = useState(false);
  const wrapperRef = useRef(null);
  const view = useMemo(() => {
    return findViewByItemId(currentResume, blockItemId, 'finish');
  }, [blockItemId, currentResume]);
  const [isHoverText, setIsHoverText] = useState(false);
  const isResumeView = route === '/resume';
  const withClickToEdit =
    isResumeView && isWithClickToEditVariant(step) && !isMobile && targetField && !hidden && !disableFeature;
  const isDescriptionDraftField = targetField === 'description' && !isTextAreaDescription;
  const disableDndForSomeField = fieldWithoutDnd?.includes(targetField) && !blockItemId;
  useEffect(() => {
    const handleRouteChangeStart = () => {
      setDisableFeature(true);
    };
    const handleRouteChangeComplete = () => {
      setDisableFeature(false);
    };
    events.on('routeChangeStart', handleRouteChangeStart);
    events.on('routeChangeComplete', handleRouteChangeComplete);
    return () => {
      events.off('routeChangeStart', handleRouteChangeStart);
      events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, []);

  const getTargetView = () => {
    let targetView = '';
    const withAddittionalBlocks =
      marvelEmitter.getActiveVariant('exp_add_block_step') === 'with_additional_add_block_step';
    const globalStep = withAddittionalBlocks ? 'other' : 'finish';
    if (!blockItemId) {
      const isFieldFromHeadingView = ['city', 'email', 'firstName', 'lastName', 'phone', 'postalCode'].includes(
        targetField,
      );
      const isFieldFromFinalizeOnly = ['title', 'userPic', 'country'].includes(targetField);
      if (isFieldFromHeadingView) {
        targetView = 'start';
      } else if (isFieldFromFinalizeOnly) {
        targetView = 'finish';
      } else {
        targetView = globalStep;
      }
    } else {
      targetView = findViewByItemId(currentResume, blockItemId, globalStep) || globalStep;
    }
    return targetView;
  };

  const handleClickEditButton = () => {
    trackEvent('click_to_edit_cta', { view: step, click_to_edit_version: CLICK_TO_EDIT_EXP_NEW_VERSION });
    const targetView = getTargetView();
    tooltipPreview.handleTooltipPreviewState(false);
    const shouldRedirect = step !== targetView;
    if (step === 'finish' || !shouldRedirect) {
      redirectToItemField({ blockItemId, targetField, parentWrapperId, isDescriptionDraftField });
      if (isDescriptionDraftField) {
        setTimeout(() => {
          triggerRedirectionToDraftEditor(blockItemId);
        }, 0);
      }
    } else if (['start', 'experience', 'education', 'skills', 'summary', 'other'].includes(step) && shouldRedirect) {
      const queryMap = {
        resumeId,
        step: targetView,
        from: asPath,
        language: locale,
        blockItemId,
        targetField,
        parentWrapperId,
        isDraft: isDescriptionDraftField,
      };
      redirecToEditView(queryMap, locale);
    }
  };

  const handleMouseMove = (event) => {
    const overText = checkIfCursorIsOverText(event);
    setIsHoverText(overText);
  };

  const checkIfCursorIsOverText = (event) => {
    const { clientX, clientY } = event;
    const elementUnderCursor = document.elementFromPoint(clientX, clientY);
    const margin = 5; // Margin in pixels
    let isOverText = false;
    const checkTextNode = (node) => {
      const range = document.createRange();
      range.selectNodeContents(node);
      const rects = range.getClientRects();
      for (const rect of rects) {
        if (
          clientX >= rect.left - margin &&
          clientX <= rect.right + margin &&
          clientY >= rect.top - margin &&
          clientY <= rect.bottom + margin
        ) {
          return true;
        }
      }
      return false;
    };
    const checkNodeRecursively = (node) => {
      if (node?.nodeType === Node.TEXT_NODE) {
        return checkTextNode(node);
      }
      for (const childNode of node?.childNodes) {
        if (checkNodeRecursively(childNode)) {
          return true;
        }
      }
      return false;
    };
    if (elementUnderCursor) {
      isOverText = checkNodeRecursively(elementUnderCursor);
    }
    return isOverText;
  };

  if (!withClickToEdit)
    return (
      <Wrapper {...wrapperProps} $finish={step == 'finish'} className="click-to-edit-wrapper">
        {children}
      </Wrapper>
    );
  return (
    <>
      <Wrapper
        onClick={handleClickEditButton}
        onMouseMove={handleMouseMove}
        $isHoverText={isHoverText}
        $withTooltip={view == 'experience'}
        {...wrapperProps}
        className={`click-to-edit-wrapper`}
        ref={wrapperRef}
        $dndIcon={!disableDndForSomeField}
        $finish={step == 'finish'}
      >
        {children}
      </Wrapper>
    </>
  );
};

const EditRedirectionWrapper = (props) => {
  const { children, wrapperProps } = props;
  if (typeof window === 'undefined')
    return (
      <Wrapper {...wrapperProps} className="click-to-edit-wrapper">
        {children}
      </Wrapper>
    );
  return <ClickToEditComponent {...props} />;
};

const Wrapper = styled.div`
  ${({ $styles }) => $styles}
  position: relative;
  cursor: text;
  img {
    cursor: ${({ $finish }) => ($finish ? 'pointer' : 'default')};
  }
  ${({ $withTooltip }) =>
    $withTooltip &&
    css`
      width: fit-content !important;
      div {
        width: fit-content;
      }
    `}

  ${({ $dndIcon }) =>
    $dndIcon == false &&
    css`
      cursor: pointer;
    `}
  ${({ $dndIcon }) =>
    $dndIcon == true &&
    css`
      cursor: move;
    `}
  ${({ $notFullWidth }) =>
    !$notFullWidth &&
    css`
      width: 100%;
    `}
`;

EditRedirectionWrapper.Proptypes = {
  children: Proptypes.element.isRequired,
  blockItemId: Proptypes.string.isRequired,
  targetField: Proptypes.string,
  parentWrapperId: Proptypes.string,
  isTextAreaDescription: Proptypes.bool,
  hidden: Proptypes.bool,
  wrapperProps: Proptypes.object,
  styledSectionProps: Proptypes.object,
  buttonProps: Proptypes.object,
};

ClickToEditComponent.Proptypes = {
  children: Proptypes.element.isRequired,
  blockItemId: Proptypes.string.isRequired,
  targetField: Proptypes.string,
  parentWrapperId: Proptypes.string,
  isTextAreaDescription: Proptypes.bool,
  hidden: Proptypes.bool,
  wrapperProps: Proptypes.object,
  styledSectionProps: Proptypes.object,
  buttonProps: Proptypes.object,
};
export default EditRedirectionWrapper;
